import { getPlatforms } from '.';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';

/**
 *
 * @param params
 * @param config
 * @returns {Promise<*>}
 */
export function getConnectedPlatforms(
  params = new QueryParamsBuilder(),
  config = { preventCancel: false, responseType: null, preventKey: null }
) {
  params.addFilter('provider.isConnected', '1');
  return getPlatforms(params, config);
}
