import { api } from '@/services/modules/dashboardSSP/campaignManager';
import { SCHEDULE_REPORTS_RESOURCE } from '@/services/keys';

export async function removeScheduleReport(scheduleReportId) {
  const partialUrl = api.createUrl({
    [SCHEDULE_REPORTS_RESOURCE]: scheduleReportId,
  });

  return await api.delete(partialUrl);
}
