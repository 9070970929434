import { api } from '.';
import { PLATFORM_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import thirdPartyEntityParser from '@/entities/ThirdParty/ThirdPartyEntityParser';

export const getResources = () => ({
  [PLATFORM_RESOURCE]: undefined,
});

/**
 *
 * @param params
 * @param config
 * @returns {Promise<*>}
 */
export async function getPlatforms(
  params = new QueryParamsBuilder(),
  config = { preventCancel: false, responseType: null, preventKey: null }
) {
  const partialUrl = api.createUrl(getResources());
  params.addInclude('provider');
  const { data } = await api.get(partialUrl, params?.build ? params.build() : params, config);
  return new ResponseBuilder(thirdPartyEntityParser.parse(data)).build();
}
