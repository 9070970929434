var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("asterix-section", {
    attrs: { status: _vm.sectionStatus, "is-loading": _vm.isLoadingSection },
    scopedSlots: _vm._u([
      {
        key: "header-right",
        fn: function () {
          return [
            _c(
              "div",
              [
                _c("sun-date-picker-v2", {
                  ref: "datePicker",
                  attrs: {
                    id: "campaign-manager-filter-date-range",
                    value: _vm.quickFiltersDate,
                    "no-header": "",
                    "no-calendar-icon": "",
                    range: true,
                    "show-date-list": "",
                    "date-list": _vm.dateList,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.onQuickFiltersDate($event.value)
                    },
                    "select:date-list-option": _vm.selectDateOption,
                  },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "div",
              { staticStyle: { height: "100%" } },
              [
                _c("ag-grid-vue", {
                  key: `campaign-manager_table_${_vm.tableKey}`,
                  staticClass: "ag-theme-alpine",
                  staticStyle: { height: "500px" },
                  attrs: {
                    pagination: "",
                    "pagination-page-size": 30,
                    "animate-rows": "",
                    "suppress-agg-func-in-header": "",
                    "group-agg-filtering": true,
                    "column-defs": _vm.tableColumnsDefinition,
                    "column-types": _vm.columnTypes,
                    "default-col-def": _vm.defaultColDef,
                    "auto-group-column-def": _vm.autoGroupColumnDef,
                    "row-data": _vm.rowData,
                    "tree-data": true,
                    "group-default-expanded": _vm.groupDefaultExpanded,
                    "cache-block-size": 20,
                    "scrollbar-width": 10,
                    "tooltip-show-delay": 100,
                    "get-data-path": _vm.getDataPath,
                  },
                  on: { "grid-ready": _vm.onGridReady },
                }),
              ],
              1
            ),
            _vm.showReportModal
              ? _c("p-g-r-create-report-modal", {
                  attrs: {
                    "create-report-fn": _vm.createReport,
                    "schedule-report-fn": _vm.scheduleReport,
                  },
                  on: { cancel: _vm.closeReloadModal },
                })
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }