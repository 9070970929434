import { getMonth } from '@sunmedia/sun-ui/src/utils';
import { addDays } from '@/utils/dateTime/addDays';
import SelectOption from '@/model/shared/SelectOption';
import currency from '@/filters/currency';
import { TODAY } from '@/utils/dateTime/today';
import { dateList, defaultColDef, columnTypes } from '../config';
import Column from '@/model/shared/Column';

export const COLUMNS = Object.freeze({
  DATE: new Column('DATE', 'breakdown'),
  PACING: new Column('PACING', 'pacing').toRight(),
  IMPRESSIONS_BOOKED: new Column('IMPRESSIONS BOOKED', 'impressionsBooked').toRight(),
  VIEWS_BOOKED: new Column('VIEWS BOOKED', 'viewsBooked').toRight(),
  CLICKS_BOOKED: new Column('CLICKS BOOKED', 'clicksBooked').toRight(),
  ENGAGEMENTS_BOOKED: new Column('ENGAGEMENTS BOOKED', 'engagementsBooked').toRight(),
  IMPRESSIONS: new Column('IMPRESSIONS', 'impressions').toRight(),
  MEASURABLE_IMPRESSIONS: new Column('MEASURABLE IMPRESSIONS', 'measurableImpressions').toRight(),
  VIEWS: new Column('VIEWS', 'views').toRight(),
  VTR: new Column('VTR', 'vtr').toRight(),
  VIEWABLE_IMPRESSIONS: new Column('VIEWABLE IMPRESSIONS', 'viewableImpressions').toRight(),
  VIEWABILITY: new Column('VIEWABILITY', 'viewability').toRight(),
  CLICKS: new Column('CLICKS', 'clicks').toRight(),
  CTR: new Column('CTR', 'ctr').toRight(),
  ENGAGEMENTS: new Column('ENGAGEMENTS', 'engagements').toRight(),
  ENGAGEMENTS_PERCENT: new Column('ENGAGEMENTS %', 'engagementsPercent'),
  UU: new Column('UU', 'uniqueUsers').toRight(),
  GROSS_REVENUE_EUR: new Column('GROSS REVENUE EUR', 'netRevenue').toRight().setCurrency('EUR'),
  GROSS_CPM_EUR: new Column('GROSS UNIT PRICE EUR', 'netECPM').toRight().setCurrency('EUR'),
  GROSS_REVENUE_USD: new Column('GROSS REVENUE USD', 'netRevenueUsd').toRight().setCurrency('USD'),
  GROSS_CPM_USD: new Column('GROSS UNIT PRICE USD', 'netECPMUsd').toRight().setCurrency('USD'),
});

export const TOTALS_COLUMNS = Object.freeze([
  new Column('Impressions', 'impressions'),
  new Column('Clicks', 'clicks'),
  new Column('Net Revenue', 'netRevenue'),
]);

const transformColumnsForAgGrid = column => {
  switch (column.value) {
    case COLUMNS.DATE.value:
      // column.width = 100;
      column.type = 'dateColumn';
      break;
    case COLUMNS.PACING.value:
      column.cellClassRules = {
        'bg-custom-red': params => params.value < 70 || params.value > 150,
        'bg-custom-yellow': params =>
          (params.value >= 70 && params.value < 95) || (params.value > 130 && params.value <= 150),
        'bg-custom-green': params => params.value >= 95 && params.value <= 130,
      };
      column.filter = false;
      break;
    case COLUMNS.VIEWABILITY.value:
    case COLUMNS.VTR.value:
    case COLUMNS.CTR.value:
    case COLUMNS.GROSS_CPM_EUR.value:
    case COLUMNS.GROSS_CPM_USD.value:
    case COLUMNS.GROSS_REVENUE_EUR.value:
    case COLUMNS.GROSS_REVENUE_USD.value:
      column.filter = false;
      column.type = 'currencyColumn';
      break;
    default:
      column.filter = false;
      column.type = 'numberColumn';
      break;
  }

  return { headerName: `${column.visibleTitle()}`, field: column.value, ...column };
};

export const CONFIG = Object.freeze({
  COMMON_ID_VIEW: 'third-party-analytics-views',
  breakdowns: {
    DATE: 'Date',
    CHART: 'Chart',
  },
  analyticsTypes: {
    BRANDING: 'BRANDING',
    OTT: 'OTT',
    YOUTUBE: 'YOUTUBE',
    PERFORMANCE: 'PERFORMANCE',
    SKIN: 'SKIN',
    PRECIO: 'PRECIO',
    CONTENT: 'CONTENT',
    KLEUP: 'KLEUP',
    OTHERS: 'OTHERS',
  },
  totalsColumn: [new SelectOption('impressions', 'Impressions'), new SelectOption('clicks', 'Clicks')],
  defaultDate: { startDate: getMonth(addDays(TODAY, -1)).startDate, endDate: addDays(TODAY, -1) },
  dateList,
  chartDataType: {
    REVENUE: 'grossRevenue',
    BYPLATFORM: 'byPlatform',
  },
  chartOptions: [new SelectOption('grossRevenue', 'Gross Revenue'), new SelectOption('byPlatform', 'By Platform')],
  yaxis: {
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
      color: '#78909C',
      offsetX: 0,
      offsetY: 0,
    },
  },
  chartLabelFormatter: val => {
    if (!val) return null;
    const [year, month, day] = val.split('-');
    return `${day}/${month}/${year}`;
  },
  currencyOptions: [new SelectOption('eur', 'EUR'), new SelectOption('usd', 'USD')],
  limitMaxDate: addDays(TODAY, -1),
  defaultColDef,
  columnTypes,
  reportOptions: [
    { id: 'CSV', name: 'CSV', value: 'CSV' },
    { id: 'XLSX', name: 'XLSX', value: 'XLSX' },
  ],
  exportParams: {
    sheetName: 'Analytic Report',
    processCellCallback: params => {
      try {
        if (!params.value) return '-';
        return params.value?.currency ? `${currency(params.value.value, params.value.currency)}` : params.value;
      } catch (e) {
        return params.value;
      }
    },
  },
  defaultColumns: [
    COLUMNS.DATE,
    COLUMNS.IMPRESSIONS,
    COLUMNS.MEASURABLE_IMPRESSIONS,
    COLUMNS.VIEWS,
    COLUMNS.VTR,
    COLUMNS.VIEWABLE_IMPRESSIONS,
    COLUMNS.VIEWABILITY,
    COLUMNS.CLICKS,
    COLUMNS.CTR,
    COLUMNS.ENGAGEMENTS,
    COLUMNS.ENGAGEMENTS_PERCENT,
    COLUMNS.UU,
    COLUMNS.GROSS_REVENUE_EUR,
    COLUMNS.GROSS_CPM_EUR,
    COLUMNS.GROSS_REVENUE_USD,
    COLUMNS.GROSS_CPM_USD,
  ],
});

export const HEADERS = CONFIG.defaultColumns.map(transformColumnsForAgGrid);
