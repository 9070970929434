import { CONFIG } from '@/views/private/modules/thirdParty/analytics/economics/config';
import { getAnalyticsKpi } from '../index';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';

/**
 *
 * @param {string} clientId
 * @param {QueryParamsBuilder} params
 * @param {object} config this is only for breakdown services
 * @returns {Promise.<{array:data,object:meta}>}
 */
export async function getSkinKpis(
  clientId,
  params = new QueryParamsBuilder(),
  config = { preventKey: 'branding_kpis' }
) {
  params.addFilter('analytic.type', CONFIG.analyticsTypes.SKIN);

  return await getAnalyticsKpi(clientId, params, config);
}
