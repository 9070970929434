import { getPlatforms } from '.';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';

/**
 *
 * @param {QueryParamsBuilder} params
 * @return {Promise.<data:object>}
 */
export function getConnectedAndDemandPlatforms(params = new QueryParamsBuilder()) {
  params.addFilter('type', 'DEMAND');
  params.addFilter('provider.isConnected', '1');
  return getPlatforms(params);
}
