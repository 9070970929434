/**
 * Analytics Totals
 * @class
 * @constructor
 * @public
 */
export default class AnalyticsTotals {
  /**
   * @typedef {Object} AnalyticsTotals
   * @property @param {Object} metrics
   */
  constructor(metrics = {}) {
    this.impressions = metrics?.impressions ?? null;
    this.clicks = metrics?.clicks ?? null;
    this.grossRevenue = metrics?.netRevenue ?? null;
    this.grossRevenueUsd = metrics?.netRevenueUsd ?? null;
  }

  static build(metrics) {
    return new AnalyticsTotals(metrics);
  }
}
