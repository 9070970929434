const CONFIG = {
  fixedMetrics: ['Name', 'Day', 'Request', 'Impressions', 'Fill Rate', 'Clicks', 'CTR'],
  groupBySelected: { id: 'date', label: 'Accumulated per Day' },
  optionalMetrics: [
    { id: 'viewableImpressions', label: 'Viewable Impressions' },
    { id: 'viewability', label: 'Viewability' },
    { id: 'views', label: 'Views' },
    { id: 'vtr', label: 'VTR' },
    { id: 'netRevenueEUR', label: 'Net Revenue €' },
    { id: 'netRevenueUSD', label: 'Net Revenue $' },
    { id: 'netCpmEUR', label: 'Net CPM €' },
    { id: 'netCpmUSD', label: 'Net CPM $' },
  ],
  optionsAllowed: {
    standard: {
      campaign: [{ id: 'date', label: 'Accumulated per Day' }],
      product: [{ id: 'date', label: 'Accumulated per Day' }],
    },
    custom: {
      campaign: [{ id: 'date', label: 'Accumulated per Day' }],
      product: [{ id: 'date', label: 'Accumulated per Day' }],
    },
  },
  defaultTimezone: 'Europe/Madrid',
  timezoneOptions: ['Europe/Madrid', 'America/New_York'],
  errorMessageEmptyEmails: 'Emails not found!',
};

export default CONFIG;
