var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _vm._v(" " + _vm._s(_vm.analyticsType) + " "),
      _c("economics-section", {
        attrs: {
          title: "Precio",
          "analytics-type": _vm.analyticsType,
          "quick-filters-date": _vm.quickFiltersDate,
          "query-params": _vm.queryParams,
          platforms: _vm.platforms,
          "get-analytics": _vm.getPrecioAnalytics,
          "get-kpis": _vm.getPrecioKpis,
        },
        on: { finishedLoad: _vm.finishedLoad },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }