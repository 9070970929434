<template>
  <section>
    <analytics-section
      :header="title"
      :table-key="tableKey"
      :loading-kpis="isLoadingKpis"
      :kpis-data="kpisData"
      :chart-loading="!isLoadingChart"
      :chart-options="chartOptions"
      :chart-series="chartSeries"
      :quick-filters-date="quickFiltersDate"
      :chart-yaxis="chartYaxis"
      :chart-type-selected="chartTypeSelected"
      :chart-revenue="chartRevenue"
      :has-filters="hasFilters"
      :report-options="reportOptions"
      :is-loading-table="isLoadingTable"
      :table-columns-definition="tableColumnsDefinition"
      :column-types="columnTypes"
      :row-model-type="rowModelType"
      :default-col-def="defaultColDef"
      :totals-column-names="totalsColumnNames"
      :totals-column="totalsColumn"
      :totals="totals"
      @chartTypeChanged="onChartTypeChange"
      @resetFilters="resetFiltersBySection"
      @handlerReport="handlerReportBySection"
      @gridReady="onGridReady"
      @changeFilters="updateActiveFiltersBySection"
    />
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AnalyticsSection from '@/components/organisms/modules/thirdParty/analytics/AnalyticsSection.vue';
import AnalyticsCellOrchestrator from '@/components/organisms/modules/thirdParty/AnalyticsCellOrchestrator.vue';
import { CONFIG, HEADERS } from '@/views/private/modules/thirdParty/analytics/economics/config.js';
import { deepClone } from '@/utils/deepClone';
import { dateToYYYYMMDD } from '@/filters/dateFilters';
import { HTTPStatusCode } from '@/model/shared/HTTPStatusCode';
import { Toast } from '@/model/shared/Toast';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { ACTIVE_CLIENT } from '@/store/modules/context/keys';
import { USER } from '@/store/modules/auth/keys';
import { CONTEXTS } from '@/model/shared/contexts';
import AnalyticsTotals from '@/entities/ThirdParty/AnalyticsTotals';

function thousandSeparator(value) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}
export default {
  name: 'EconomicsSection',
  components: {
    AnalyticsSection,
    // eslint-disable-next-line vue/no-unused-components
    AnalyticsCellOrchestrator,
  },
  props: {
    title: { type: String, default: () => 'Title', required: true },
    analyticsType: { type: String, default: () => null, required: true },
    quickFiltersDate: { type: Object, default: () => {}, required: true },
    queryParams: { type: Object, default: () => {}, required: true },
    platforms: { type: Array, default: () => [], required: true },
    getAnalytics: { type: Function, required: true },
    getKpis: { type: Function, required: true },
  },
  data: () => ({
    // Config requests -----------------------------------
    sortByDefault: { field: 'analytic.date', order: 'DESC' },
    // Config for chart -----------------------------------
    chartOptions: CONFIG.chartOptions,
    chartFormatter: CONFIG.chartLabelFormatter,
    chartTypeSelected: CONFIG.chartDataType.REVENUE,
    chartYaxis: CONFIG.yaxis,
    isLoadingChart: false,
    chartData: {
      [CONFIG.chartDataType.REVENUE]: new Map(),
      [CONFIG.chartDataType.BYPLATFORM]: new Map(),
    },
    // Config Table -----------------------------------
    gridApi: null,
    columnApi: null,
    hasFilters: false,
    reportOptions: CONFIG.reportOptions,
    rowModelType: 'serverSide',
    headers: HEADERS,
    columnTypes: CONFIG.columnTypes,
    defaultColDef: CONFIG.defaultColDef,
    totalsColumn: CONFIG.totalsColumn,
    kpisData: {},
    isLoadingKpis: false,
    isLoadingTable: false,
    totals: {},
    // Config download report -----------------------------------
    exportParams: CONFIG.exportParams,
  }),
  computed: {
    ...mapGetters({
      activeClient: ACTIVE_CLIENT,
      user: USER,
    }),
    tableKey() {
      return `table_${this.analyticsType}_key`;
    },
    fileNameReport() {
      return `_${this.analyticsType}-analytics`;
    },
    // Computed for charts -----------------------------------
    chartRevenue() {
      return this.chartTypeSelected === CONFIG.chartDataType.REVENUE;
    },
    chartSeries() {
      const chartSeries = [];
      if (this.chartTypeSelected === CONFIG.chartDataType.BYPLATFORM) {
        this.chartData[CONFIG.chartDataType.BYPLATFORM].forEach((chartInfoMap, platformName) => {
          const chartSerie = {
            name: platformName,
            type: 'line',
            data: chartInfoMap?.grossRevenue || new Map(),
          };
          chartSeries.push(chartSerie);
        });

        if (chartSeries.length === 0) return chartSeries;

        const chartTypeSelected = this.chartTypeSelected;
        chartSeries[0].xaxisConfigExtend = { labels: { formatter: this.chartFormatter } };
        chartSeries[0].yaxisConfigExtend = {
          labels: {
            formatter(val) {
              switch (chartTypeSelected) {
                case CONFIG.chartDataType.BYPLATFORM:
                  if (!val) return '0,00';
                  return `${thousandSeparator(
                    val.toLocaleString('es-ES', {
                      style: 'decimal',
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })
                  )}€`;
                default:
                  if (!val) return '0';
                  return thousandSeparator(val.toLocaleString('es-ES'));
              }
            },
          },
        };
      } else {
        chartSeries.push({
          name: 'Gross revenue',
          type: 'line',
          data: this.chartData[CONFIG.chartDataType.REVENUE],
        });
        if (chartSeries.length === 0) return chartSeries;

        chartSeries[0].yaxisConfigExtend = {
          labels: {
            formatter(val) {
              if (!val) return '0,00';
              return `${thousandSeparator(
                val.toLocaleString('es-ES', {
                  style: 'decimal',
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              )}€`;
            },
          },
        };
      }

      return chartSeries;
    },
    // Computed for table -----------------------------------
    contextUserRole() {
      return this.user?.contextRoles.find(contextRole => contextRole.context === CONTEXTS.THIRD_PARTY.id).role;
    },
    availableColumnsByBreakdown() {
      return this.headers.filter(
        header => !header.rolesAllowed.length || header.rolesAllowed.includes(this.contextUserRole)
      );
    },
    tableColumnsDefinition() {
      return [...this.availableColumnsByBreakdown].map(
        ({
          headerName,
          field,
          cellClassRules,
          visible,
          maxWidth,
          minWidth,
          width,
          filter,
          filterParams,
          type,
          pinned,
          sort,
          suppressSizeToFit,
          suppressAutoSize,
          comparator,
        }) => ({
          headerName,
          field,
          hide: !visible,
          cellClassRules,
          maxWidth,
          minWidth,
          width,
          pinned,
          filter,
          filterParams,
          type,
          sort,
          suppressSizeToFit,
          suppressAutoSize,
          comparator,
          headerTooltip: headerName,
        })
      );
    },
    totalsColumnNames() {
      return CONFIG.totalsColumn.map(col => col.name);
    },
  },
  watch: {
    isLoadingTable: 'handleLoadingChange',
    isLoadingKpis: 'handleLoadingChange',
    isLoadingChart: 'handleLoadingChange',
  },
  mounted() {
    this.initSection(this.analyticsType);
  },
  methods: {
    ...mapActions({
      createToast: CREATE_TOAST,
    }),
    // Methods for load data -----------------------------------
    async initSection(section) {
      await this.getAnalyticKpi(section);
      await this.getAnalyticChart(section);
    },
    async reload(section) {
      this.resetChartData();
      await this.getAnalyticChart(section);
      this.gridApi.refreshServerSideStore();
    },
    async getAnalyticKpi(analyticType) {
      this.isLoadingKpis = true;
      try {
        const auxParams = deepClone(this.queryParams);

        this.kpisData = await this.getKpis(this.activeClient.id, auxParams, {
          preventKey: `${this.analyticsType}_kpis`,
        });
      } catch (error) {
        if (error.code !== HTTPStatusCode.Cancel) {
          await this.createToast(Toast.error(`We can not get ${analyticType} KPIS.`, error));
        }
      }
      this.isLoadingKpis = false;
    },
    async getAnalyticItems() {
      try {
        const auxParams = deepClone(this.queryParams);
        auxParams.page = 1;
        auxParams.itemsPerPage = 2000;
        auxParams.addFilter('breakdown', CONFIG.breakdowns.DATE);
        auxParams.addSort(this.sortByDefault.field, this.sortByDefault.order);

        return await this.getAnalytics(this.activeClient.id, auxParams, {
          preventKey: `${this.analyticsType}_data`,
        });
      } catch (error) {
        this.isLoadingTable = false;
        if (error.code !== HTTPStatusCode.Cancel) {
          await this.createToast(Toast.error(`We can not get analytic`, error));
        }
      }
    },
    async getAnalyticChart(analyticType) {
      this.isLoadingChart = true;
      try {
        const auxParams = deepClone(this.queryParams);
        auxParams.addFilter('breakdown', CONFIG.breakdowns.CHART);

        const { data } = await this.getAnalytics(this.activeClient.id, auxParams, {
          preventKey: `${this.analyticsType}_chart`,
        });
        this.formatGrossRevenueSeries(data, analyticType);
        this.formatDataToSeriesChart(data, analyticType);
      } catch (error) {
        if (error.code !== HTTPStatusCode.Cancel) {
          await this.createToast(Toast.error(`We can not get ${analyticType} Chart.`, error));
        }
      }
      this.isLoadingChart = false;
    },
    // Methods for charts -----------------------------------
    formatGrossRevenueSeries(rawData, analyticType) {
      if (!rawData) return [];
      const chartPoints = new Map();
      try {
        rawData.forEach(pointByPlatform => {
          const date = pointByPlatform.date;
          if (!chartPoints.has(date)) {
            chartPoints.set(date, parseFloat(pointByPlatform.grossRevenue));
          } else {
            const newPoint = chartPoints.get(date) + parseFloat(pointByPlatform.grossRevenue);
            chartPoints.set(date, newPoint);
          }
        });
        this.chartData[CONFIG.chartDataType.REVENUE] = chartPoints;
      } catch (error) {
        this.createToast(Toast.error(`We can not parse graph data. ${analyticType} Chart`, error));
      }
    },
    formatDataToSeriesChart(rawData, analyticType) {
      if (!rawData) return [];
      try {
        const { REVENUE } = CONFIG.chartDataType;
        const platformMap = new Map();

        this.platforms.forEach(platform => {
          const dataByPlatform = rawData.filter(raw => raw.platformName === platform.name);
          if (!dataByPlatform.length) {
            platformMap.set(platform.name, { [REVENUE]: new Map() });
          }
          dataByPlatform.forEach(chartPoint => {
            const chartData = platformMap.has(platform.name)
              ? platformMap.get(platform.name)
              : { [REVENUE]: new Map() };
            const key = chartPoint.date;
            chartData[REVENUE].set(key, parseFloat(chartPoint.grossRevenue)?.toFixed(3) || 0);

            platformMap.set(platform.name, chartData);
          });
        });

        this.chartData[CONFIG.chartDataType.BYPLATFORM] = platformMap;
      } catch (error) {
        this.createToast(Toast.error(`We can not parse graph data. ${analyticType} Chart`, error));
      }
    },
    onChartTypeChange(event) {
      this.chartTypeSelected = event.id;
    },
    resetChartData() {
      this.isLoadingChart = true;
      this.chartData = {
        [CONFIG.chartDataType.REVENUE]: new Map(),
        [CONFIG.chartDataType.BYPLATFORM]: new Map(),
      };
    },
    // Methods for table -----------------------------------
    onGridReady({ api, columnApi }) {
      this.gridApi = api;
      this.columnApi = columnApi;

      this.gridApi.setServerSideDatasource({
        getRows: async params => {
          this.gridApi.hideOverlay();
          this.isLoadingTable = true;
          this.gridApi.showLoadingOverlay();

          const { data, metrics } = await this.getAnalyticItems();
          this.totals = AnalyticsTotals.build(metrics);

          params.success({ rowData: data });

          if (this.gridApi.getDisplayedRowCount() === 0) this.gridApi.showNoRowsOverlay();
          else {
            this.gridApi.hideOverlay();
          }
          this.isLoadingTable = false;
          this.gridApi.sizeColumnsToFit();
        },
      });
    },
    // Methods emits for tables -----------------------------------
    // Se activa cuando los filtros dentro de la tabla cambian
    // De momento no tiene utilidad
    updateActiveFiltersBySection() {
      this.hasFilters = !!this.gridApi?.isAnyFilterPresent();
    },
    resetFiltersBySection() {
      this.gridApi.setFilterModel(null);
    },
    // Methods for handle download reports -----------------------------------
    handlerReportBySection(value) {
      const params = deepClone(this.exportParams);
      const date = dateToYYYYMMDD(new Date());
      if (value === 'CSV') {
        params.fileName = `${date + this.fileNameReport}.csv`;
        this.gridApi.exportDataAsCsv(params);
      } else if (value === 'XLSX') {
        params.fileName = `${date + this.fileNameReport}.xlsx`;
        this.gridApi.exportDataAsExcel(params);
      }
    },
    handleLoadingChange() {
      const isLoadingAll = this.isLoadingTable || this.isLoadingKpis || this.isLoadingChart;
      if (isLoadingAll === false) {
        this.$emit('finishedLoad', true);
      }
    },
  },
};
</script>
