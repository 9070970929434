var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("economics-section", {
        attrs: {
          title: "Performance",
          "analytics-type": _vm.analyticsType,
          "quick-filters-date": _vm.quickFiltersDate,
          "query-params": _vm.queryParams,
          platforms: _vm.platforms,
          "get-analytics": _vm.getPerformanceAnalytics,
          "get-kpis": _vm.getPerformanceKpis,
        },
        on: { finishedLoad: _vm.finishedLoad },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }