import { INGESTION_RESOURCE, IMPORT_RESOURCE } from '@/services/keys';
import { api } from '.';

export const getResources = insertionId => ({
  [INGESTION_RESOURCE]: insertionId,
  [IMPORT_RESOURCE]: undefined,
});

/**
 *
 * @param {Platform} platform
 * @return {Promise.<data:object>}
 */
export function reloadPlatformData(platform) {
  const partialUrl = api.createUrl(getResources());

  return api.create(partialUrl, platform.payload());
}
