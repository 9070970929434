import { getAnalyticsV2 } from './getAnalyticsV2';
import { getAnalyticsTotalsV2 } from './getAnalyticsTotalsV2';
import { getAnalyticsKpi } from './getAnalyticsKpi';
import { getBrandingAnalytics } from './branding/getBrandingAnalytics';
import { getBrandingKpis } from './branding/getBrandingKpis';
import { getBrandingTotals } from './branding/getBrandingTotals';
import { getOthersTotals } from './others/getOthersTotals';
import { getOthersAnalytics } from './others/getOthersAnalytics';
import { getOthersKpis } from './others/getOthersKpis';
import { getOttTotals } from './ott/getOttTotals';
import { getOttAnalytics } from './ott/getOttAnalytics';
import { getOttKpis } from './ott/getOttKpis';
import { getYoutubeTotals } from './youtube/getYoutubeTotals';
import { getYoutubeAnalytics } from './youtube/getYoutubeAnalytics';
import { getYoutubeKpis } from './youtube/getYoutubeKpis';
import { getPerformanceTotals } from './performance/getPerformanceTotals';
import { getPerformanceAnalytics } from './performance/getPerformanceAnalytics';
import { getPerformanceKpis } from './performance/getPerformanceKpis';
import { getSkinTotals } from './skin/getSkinTotals';
import { getSkinAnalytics } from './skin/getSkinAnalytics';
import { getSkinKpis } from './skin/getSkinKpis';
import { getContentAnalytics } from './content/getContentAnalytics';
import { getContentKpis } from './content/getContentKpis';
import { getKleupAnalytics } from './kleup/getKleupAnalytics';
import { getKleupKpis } from './kleup/getKleupKpis';
import { getPrecioAnalytics } from './precio/getPrecioAnalytics';
import { getPrecioKpis } from './precio/getPrecioKpis';

export {
  getAnalyticsV2,
  getAnalyticsTotalsV2,
  getAnalyticsKpi,
  getBrandingAnalytics,
  getBrandingKpis,
  getBrandingTotals,
  getOthersTotals,
  getOthersAnalytics,
  getOthersKpis,
  getOttTotals,
  getOttAnalytics,
  getOttKpis,
  getYoutubeTotals,
  getYoutubeAnalytics,
  getYoutubeKpis,
  getPerformanceTotals,
  getPerformanceAnalytics,
  getPerformanceKpis,
  getSkinTotals,
  getSkinAnalytics,
  getSkinKpis,
  getContentAnalytics,
  getContentKpis,
  getKleupAnalytics,
  getKleupKpis,
  getPrecioAnalytics,
  getPrecioKpis,
};
