var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isShow
    ? _c("div", { staticClass: "flex flex-col w-full" }, [
        _c(
          "label",
          {
            staticClass: "font-bold text-left text-sm mr-1 text-gray-700 mb-1",
          },
          [_vm._v("Current Reports")]
        ),
        !_vm.isLoading
          ? _c("div", { staticClass: "w-full" }, [
              !_vm.emptyData
                ? _c("div", [
                    _c(
                      "table",
                      { staticClass: "w-full" },
                      [
                        _c(
                          "tr",
                          [
                            _vm._l(_vm.headers, function (header, index) {
                              return _c(
                                "th",
                                {
                                  key: `header-${index}`,
                                  staticClass:
                                    "font-bold text-left text-xs text-gray-700 w-1/5",
                                },
                                [_vm._v(" " + _vm._s(header.name) + " ")]
                              )
                            }),
                            _c(
                              "th",
                              {
                                staticClass:
                                  "font-bold text-center text-xs text-gray-700",
                              },
                              [_vm._v("Actions")]
                            ),
                          ],
                          2
                        ),
                        _vm._l(_vm.scheduledEmails, function (row) {
                          return _c(
                            "tr",
                            { key: row.id },
                            [
                              _vm._l(_vm.headers, function (header, index) {
                                return _c(
                                  "td",
                                  {
                                    key: `row-${index}`,
                                    staticClass: "text-left w-1/5",
                                  },
                                  [
                                    _c("span", { staticClass: "text-xs" }, [
                                      _vm._v(_vm._s(row[header.field])),
                                    ]),
                                  ]
                                )
                              }),
                              _c("td", { staticClass: "text-center w-1/5" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "flex justify-center w-full",
                                    on: {
                                      click: function ($event) {
                                        return _vm.onDelete(row)
                                      },
                                    },
                                  },
                                  [
                                    !_vm.isLoadingActions
                                      ? _c("sun-close-svg", {
                                          staticClass: "cursor-pointer",
                                          attrs: {
                                            title: "Delete",
                                            color: "red",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.isLoadingActions
                                      ? _c("sun-loading", {
                                          staticClass: "h-6 text-orange-500",
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            2
                          )
                        }),
                      ],
                      2
                    ),
                  ])
                : _vm._e(),
              _vm.emptyData
                ? _c("div", [
                    _c(
                      "span",
                      { staticClass: "font-bold text-xs text-gray-600" },
                      [_vm._v("There are no scheduled reports yet!")]
                    ),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _vm.isLoading
          ? _c(
              "div",
              { staticClass: "flex w-full justify-center items-center" },
              [_c("sun-loading", { staticClass: "h-12 text-orange-400" })],
              1
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }