<template>
  <AnalyticsListV2 analytic-type="KLEUP"> </AnalyticsListV2>
</template>
<script>
import AnalyticsListV2 from '@/views/private/modules/thirdParty/analytics/AnalyticsListV2.vue';

export default {
  name: 'Kleup',
  components: { AnalyticsListV2 },
};
</script>
