import { api } from '@/services/modules/dashboardSSP/campaignManager';
import { SCHEDULE_REPORTS_RESOURCE } from '@/services/keys';

export async function scheduleReport(params = {}) {
  const partialUrl = api.createUrl({
    [SCHEDULE_REPORTS_RESOURCE]: undefined,
  });

  const { data } = await api.create(partialUrl, params);

  return data;
}
