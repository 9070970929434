import { api } from '@/services/modules/dashboardSSP/campaignManager';
import { CAMPAIGN_MANAGER_RESOURCE, REPORT_RESOURCE } from '@/services/keys';

export async function createReport(params = {}, config = { preventKey: 'create_report', responseType: 'blob' }) {
  const partialUrl = api.createUrl({
    [CAMPAIGN_MANAGER_RESOURCE]: undefined,
    [REPORT_RESOURCE]: undefined,
  });

  const response = await api.get(partialUrl, params, config);

  return new Blob([response.data]);
}
