import { api } from '.';
import { PLATFORM_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import { Platform } from '@/entities/ThirdParty/Platform';

/**
 *
 * @param {string} platformId
 * @returns {Promise.<Platform>}
 */
export default async function (platformId, params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl({
    [PLATFORM_RESOURCE]: platformId,
  });
  const {
    data: { data },
  } = await api.get(partialUrl, params.build());
  return new Platform(data.id, data.attributes.name, data.attributes.type);
}
