import { isValidStringDate } from '@/utils/validation/isValidStringDate';
import { dateList as defaultDateList } from '@/utils/dateTime/dateList.js';

export const CUSTOM_OPTION = 'Custom';

export const dateList = defaultDateList;

export const defaultColDef = {
  sortable: true,
  unSortIcon: true,
  filter: true,
  resizable: true,
  floatingFilter: true,
  minWidth: 50,
  width: 100,
  cellRenderer: 'AnalyticsCellOrchestrator',
  suppressMenu: true,
};

export const dateComparator = (filterLocalDateAtMidnight, dateAsString) => {
  if (dateAsString == null || !isValidStringDate(dateAsString)) {
    return -1;
  }

  const dateParts = dateAsString.split('-');
  const year = Number(dateParts[0]);
  const month = Number(dateParts[1]) - 1;
  const day = Number(dateParts[2]);
  const cellDate = new Date(year, month, day);

  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  }
  if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  }
  return 0;
};

export const textFilterValueGetter = params => {
  const colId = params.column.getColId();
  const value = params.data[colId];
  const valueIsNA = value === 'N/A';
  const parentData = params?.node?.parent?.aggData;
  const parentHasData = !!parentData && parentData[colId];

  if (valueIsNA && parentHasData) {
    return parentData[colId];
  }

  return params.data[colId];
};

export const columnTypes = {
  dateColumn: {
    filter: 'agDateColumnFilter',
    filterParams: {
      comparator: dateComparator,
    },
  },
  textColumn: {
    filter: 'agTextColumnFilter',
    filterValueGetter: textFilterValueGetter,
  },
  numberColumn: {
    filter: 'agNumberColumnFilter',
  },
  currencyColumn: {
    filter: 'agNumberColumnFilter',
    comparator: (a, b) => (a || 0) - (b || 0),
    filterValueGetter: params => {
      const colId = params.column.colId;
      const value = params.data[colId] || null;

      return value?.value;
    },
    filterParams: {
      numberParser: text => (text == null ? null : parseFloat(text.replace(',', '.'))),
    },
  },
};
