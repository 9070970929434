import { getPlatforms } from '.';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';

/**
 *
 * @param {QueryParamsBuilder} params
 * @return {Promise.<data:object>}
 */
export function getNotConnectedPlatforms(params = new QueryParamsBuilder()) {
  params.addFilter('provider.isConnected', '0');
  return getPlatforms(params);
}
