import { CONFIG } from '@/views/private/modules/thirdParty/analytics/economics/config';
import { getAnalyticsTotalsV2 } from '../index';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';

/**
 *
 * @param {string} clientId
 * @param {QueryParamsBuilder} params
 * @param {object} config this is only for breakdown services
 * @returns {Promise.<{array:data,object:meta}>}
 */
export async function getOttTotals(clientId, params = new QueryParamsBuilder(), config = { preventKey: 'ott_totals' }) {
  params.addFilter('analytic.type', CONFIG.analyticsTypes.OTT);

  return await getAnalyticsTotalsV2(clientId, params, config);
}
