var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("asterix-section", {
    attrs: { status: _vm.sectionStatus, "is-loading": _vm.isLoadingSection },
    scopedSlots: _vm._u([
      {
        key: "header-right",
        fn: function () {
          return [
            _c(
              "div",
              [
                _c("sun-date-picker-v2", {
                  ref: "datePicker",
                  attrs: {
                    id: "economics-filter-date-range",
                    value: _vm.quickFiltersDate,
                    "max-date": _vm.limitDateRange.max,
                    "no-header": "",
                    "no-calendar-icon": "",
                    range: true,
                    "show-date-list": "",
                    "date-list": _vm.dateList,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.onQuickFiltersDate($event.value)
                    },
                    "select:date-list-option": _vm.selectDateOption,
                  },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            _vm.activeClientId
              ? _c("ingestion-job-notifications", {
                  attrs: {
                    service: _vm.getIngestionJobNotifications,
                    "client-id": _vm.activeClientId,
                  },
                })
              : _vm._e(),
            _c("branding-economics-section", {
              key: `branding-${_vm.reloadSections}`,
              attrs: {
                "quick-filters-date": _vm.quickFiltersDate,
                "query-params": _vm.setParamsToTable(),
                platforms: _vm.allPlatforms,
              },
              on: {
                finishedLoad: function ($event) {
                  _vm.isLoadingSectionBranding = !$event
                },
              },
            }),
            _c("ott-economics-section", {
              key: `ott-${_vm.reloadSections}`,
              attrs: {
                "quick-filters-date": _vm.quickFiltersDate,
                "query-params": _vm.setParamsToTable(),
                platforms: _vm.allPlatforms,
              },
              on: {
                finishedLoad: function ($event) {
                  _vm.isLoadingSectionOtt = !$event
                },
              },
            }),
            _c("youtube-economics-section", {
              key: `youtube-${_vm.reloadSections}`,
              attrs: {
                "quick-filters-date": _vm.quickFiltersDate,
                "query-params": _vm.setParamsToTable(),
                platforms: _vm.allPlatforms,
              },
              on: {
                finishedLoad: function ($event) {
                  _vm.isLoadingSectionYoutube = !$event
                },
              },
            }),
            _c("performance-economics-section", {
              key: `performance-${_vm.reloadSections}`,
              attrs: {
                "quick-filters-date": _vm.quickFiltersDate,
                "query-params": _vm.setParamsToTable(),
                platforms: _vm.allPlatforms,
              },
              on: {
                finishedLoad: function ($event) {
                  _vm.isLoadingSectionPerformance = !$event
                },
              },
            }),
            _c("skin-economics-section", {
              key: `skin-${_vm.reloadSections}`,
              attrs: {
                "quick-filters-date": _vm.quickFiltersDate,
                "query-params": _vm.setParamsToTable(),
                platforms: _vm.allPlatforms,
              },
              on: {
                finishedLoad: function ($event) {
                  _vm.isLoadingSectionSkin = !$event
                },
              },
            }),
            _c("precio-economics-section", {
              key: `precio-${_vm.reloadSections}`,
              attrs: {
                "quick-filters-date": _vm.quickFiltersDate,
                "query-params": _vm.setParamsToTable(),
                platforms: _vm.allPlatforms,
              },
              on: {
                finishedLoad: function ($event) {
                  _vm.isLoadingSectionPrecio = !$event
                },
              },
            }),
            _c("content-economics-section", {
              key: `content-${_vm.reloadSections}`,
              attrs: {
                "quick-filters-date": _vm.quickFiltersDate,
                "query-params": _vm.setParamsToTable(),
                platforms: _vm.allPlatforms,
              },
              on: {
                finishedLoad: function ($event) {
                  _vm.isLoadingSectionContent = !$event
                },
              },
            }),
            _c("others-economics-section", {
              key: `others-${_vm.reloadSections}`,
              attrs: {
                "quick-filters-date": _vm.quickFiltersDate,
                "query-params": _vm.setParamsToTable(),
                platforms: _vm.allPlatforms,
              },
              on: {
                finishedLoad: function ($event) {
                  _vm.isLoadingSectionOthers = !$event
                },
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }