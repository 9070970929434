<template>
  <section>
    <economics-section
      title="Branding Web"
      :analytics-type="analyticsType"
      :quick-filters-date="quickFiltersDate"
      :query-params="queryParams"
      :platforms="platforms"
      :get-analytics="getBrandingAnalytics"
      :get-kpis="getBrandingKpis"
      @finishedLoad="finishedLoad"
    />
  </section>
</template>

<script>
import { CONFIG } from '@/views/private/modules/thirdParty/analytics/economics/config.js';
import EconomicsSection from '@/components/organisms/modules/thirdParty/analytics/EconomicsSection.vue';
import { getBrandingAnalytics, getBrandingKpis } from '@/services/modules/ThirdParty/analytics/v2/';

export default {
  name: 'BrandingEconomicsSection',
  components: {
    EconomicsSection,
  },
  props: {
    quickFiltersDate: { type: Object, default: () => {}, required: true },
    queryParams: { type: Object, default: () => {}, required: true },
    platforms: { type: Array, default: () => [], required: true },
  },
  data: () => ({
    analyticsType: CONFIG.analyticsTypes.BRANDING,
  }),
  methods: {
    getBrandingAnalytics,
    getBrandingKpis,
    finishedLoad(value) {
      this.$emit('finishedLoad', value);
    },
  },
};
</script>
