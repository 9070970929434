import { dashboardSSP } from '@/services/modules/dashboardSSP';
import { getOpportunities } from './getOpportunities';
import { getOpportunityIntervalDate } from './getOpportunityIntervalDate';
import { createReport } from './createReport';
import { scheduleReport } from './scheduleReport';
import { removeScheduleReport } from './removeScheduleReport';
import { getScheduleReports } from './getScheduleReports';

export const api = dashboardSSP;

export {
  getOpportunities,
  getOpportunityIntervalDate,
  createReport,
  scheduleReport,
  getScheduleReports,
  removeScheduleReport,
};
