import { CONFIG } from '@/views/private/modules/thirdParty/analytics/economics/config';
import { getAnalyticsV2 } from '../index';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';

/**
 *
 * @param {string} clientId
 * @param {QueryParamsBuilder} params
 * @param {object} config this is only for breakdown services
 * * @returns {object}
 *  */
export async function getPerformanceAnalytics(
  clientId,
  params = new QueryParamsBuilder(),
  config = { preventKey: 'performance_data' }
) {
  params.addFilter('analytic.type', CONFIG.analyticsTypes.PERFORMANCE);

  const { data, metrics } = await getAnalyticsV2(clientId, params, config);
  return { data, metrics };
}
