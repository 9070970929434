var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "border shadow rounded-lg mb-6 bg-white" },
    [
      _c("div", { staticClass: "bg-white w-full flex p-5 rounded-lg" }, [
        _c(
          "h2",
          { staticClass: "text-2xl font-bold text-gray-700 uppercase" },
          [_vm._v(_vm._s(_vm.header))]
        ),
      ]),
      _c(
        "box",
        {
          staticClass:
            "grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 mb-8 rounded-none shadow-none",
        },
        [
          _c("stats-box", {
            attrs: {
              title: "Gross Revenue (Yesterday)",
              "is-loading": _vm.loadingKpis,
            },
            scopedSlots: _vm._u(
              [
                _vm.kpisData.yesterday && _vm.currencySelected === 0
                  ? {
                      key: "value",
                      fn: function () {
                        return [
                          _vm._v(
                            _vm._s(
                              _vm._f("currencyEurFilter")(
                                _vm.kpisData.yesterday.grossRevenue
                              )
                            )
                          ),
                        ]
                      },
                      proxy: true,
                    }
                  : _vm.kpisData.yesterday && _vm.currencySelected === 1
                  ? {
                      key: "value",
                      fn: function () {
                        return [
                          _vm._v(
                            _vm._s(
                              _vm._f("currencyUsdFilter")(
                                _vm.kpisData.yesterday.grossRevenueUsd
                              )
                            )
                          ),
                        ]
                      },
                      proxy: true,
                    }
                  : {
                      key: "value",
                      fn: function () {
                        return [_vm._v("N/A")]
                      },
                      proxy: true,
                    },
              ],
              null,
              true
            ),
          }),
          _c("stats-box", {
            attrs: {
              title: "Gross Revenue (MTD)",
              "is-loading": _vm.loadingKpis,
            },
            scopedSlots: _vm._u(
              [
                _vm.kpisData.mtd && _vm.currencySelected === 0
                  ? {
                      key: "value",
                      fn: function () {
                        return [
                          _vm._v(
                            _vm._s(
                              _vm._f("currencyEurFilter")(
                                _vm.kpisData.mtd.grossRevenue
                              )
                            )
                          ),
                        ]
                      },
                      proxy: true,
                    }
                  : _vm.kpisData.mtd && _vm.currencySelected === 1
                  ? {
                      key: "value",
                      fn: function () {
                        return [
                          _vm._v(
                            _vm._s(
                              _vm._f("currencyUsdFilter")(
                                _vm.kpisData.mtd.grossRevenueUsd
                              )
                            )
                          ),
                        ]
                      },
                      proxy: true,
                    }
                  : {
                      key: "value",
                      fn: function () {
                        return [_vm._v("N/A")]
                      },
                      proxy: true,
                    },
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flex mb-5" },
        [
          _c("analytics-chart", {
            attrs: {
              loading: _vm.chartLoading,
              "hide-totals": "",
              "chart-options": _vm.chartOptions,
              "chart-series": _vm.chartSeries,
              "date-start": _vm.quickFiltersDate.startDate,
              "date-end": _vm.quickFiltersDate.endDate,
              "hide-no-data-image": "",
              yaxis: _vm.chartYaxis,
              "default-option-selected": _vm.chartTypeSelected,
            },
            on: { change: _vm.onChartTypeChange },
            scopedSlots: _vm._u(
              [
                _vm.chartRevenue && _vm.currencySelected === 0
                  ? {
                      key: "total",
                      fn: function ({ total }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("currencyEurFilter")(total)) +
                              " "
                          ),
                        ]
                      },
                    }
                  : _vm.chartRevenue && _vm.currencySelected === 1
                  ? {
                      key: "total",
                      fn: function ({ total }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("currencyUsdFilter")(total)) +
                              " "
                          ),
                        ]
                      },
                    }
                  : {
                      key: "total",
                      fn: function ({ total }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("valueToLocalString")(total)) +
                              " "
                          ),
                        ]
                      },
                    },
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "flex flex-col items-end justify-end w-full p-2 rounded-t-md bg-white lg:flex-row lg:items-center border-t",
        },
        [
          _c(
            "div",
            { staticClass: "flex flex-row items-center mb-2 ml-5 lg:mb-0" },
            [
              _c(
                "asterix-dropdown",
                {
                  attrs: {
                    "button-classes":
                      "flex hover:bg-gray-300 focus:bg-gray-800 text-gray-700 focus:text-gray-200 rounded h-10 px-4 items-center",
                    disabled: _vm.isLoadingTable,
                    options: _vm.reportOptions,
                  },
                  on: { change: _vm.handlerReport },
                  scopedSlots: _vm._u([
                    {
                      key: "dropdown-header",
                      fn: function () {
                        return [
                          _c(
                            "p",
                            {
                              staticClass:
                                "p-2 pt-0 text-sm font-bold text-left whitespace-no-wrap",
                            },
                            [_vm._v("Download as")]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "icon",
                      fn: function (state) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "transition-100",
                              class: !state.visible ? "rotate-180" : "",
                            },
                            [
                              _c("up-svg", {
                                staticClass: "w-2 h-2",
                                class: state.disabled
                                  ? "text-gray-400"
                                  : "text-gray-500",
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "span",
                    { staticClass: "block w-4 mr-2" },
                    [_c("download-svg")],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "flex flex-col w-full mb-8 relative" },
        [
          _c("ag-grid-vue", {
            key: _vm.tableKey,
            staticClass: "ag-theme-alpine",
            staticStyle: { height: "500px" },
            attrs: {
              "column-defs": _vm.tableColumnsDefinition,
              "column-types": _vm.columnTypes,
              pagination: "",
              "animate-rows": "",
              "row-model-type": _vm.rowModelType,
              "scrollbar-width": 10,
              "suppress-agg-func-in-header": true,
              "default-col-def": _vm.defaultColDef,
              "tooltip-show-delay": 100,
            },
            on: {
              "grid-ready": _vm.emitGridReady,
              "filter-changed": _vm.emitChangeFilters,
            },
          }),
        ],
        1
      ),
      _vm.isLoadingTable
        ? _c("analytics-total-loader", {
            attrs: { columns: _vm.totalsColumnNames },
          })
        : _vm.totals
        ? _c(
            "box",
            { staticClass: "shadow-none rounded-none" },
            [
              _vm._l(_vm.totalsColumn, function (col, $i) {
                return _c("box-column", {
                  key: `box-column-${$i}`,
                  attrs: {
                    title: col.name,
                    value: _vm._f("valueToLocalString")(_vm.totals[col.id]),
                  },
                })
              }),
              _vm.currencySelected === 0
                ? _c("box-column", {
                    attrs: {
                      title: "Gross Revenue",
                      value: _vm._f("currencyEurFilter")(
                        _vm.totals.grossRevenue,
                        _vm.notAvailable,
                        "es-es"
                      ),
                    },
                  })
                : _vm._e(),
              _vm.currencySelected === 0
                ? _c("box-column", {
                    attrs: {
                      title: "Gross Revenue USD",
                      value: _vm._f("currencyEurFilter")(
                        _vm.totals.grossRevenueUsd,
                        _vm.notAvailable,
                        "es-es"
                      ),
                    },
                  })
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }