<template>
  <asterix-form-modal
    title="Create Product Report"
    closable
    modal-class="sm:max-w-6xl"
    @submit="submitForm"
    @keypress.enter="submitForm"
    @cancel="closeModal"
  >
    <template #content>
      <div class="overflow-visible pb-6 px-3">
        <form-row>
          <div class="flex flex-col w-full">
            <sun-label-group text="Fixed metrics" class="mb-6">
              <span
                v-for="label in fixedMetrics"
                :key="label"
                :class="`px-2 py-1 mr-1 border leading-tight align-middle inline-flex items-center bg-green-300 rounded-full`"
              >
                <span :class="`text-xs whitespace-no-wrap text-gray-900`">{{ label }}</span>
              </span>
            </sun-label-group>
            <sun-label-group text="Optional metrics" class="mb-6">
              <template #title>
                <div class="flex w-full flex-col">
                  <label class="font-bold text-left text-sm mr-1 sun-label text-gray-700 mb-1">Optional metrics</label>
                  <div class="flex flex-row flex-nowrap mb-1">
                    <label class="text-left text-xs mr-1 sun-label">Select All</label>
                    <sun-checkbox
                      :class="`rounded-full`"
                      color="green"
                      :value="selectedAllValue"
                      @change="selectOptions($event.value)"
                    />
                  </div>
                </div>
              </template>

              <div class="w-full flex flex-wrap">
                <div
                  v-for="(option, index) in optionalMetrics"
                  :key="`option__${index}`"
                  class="flex items-center py-1 px-2 mr-1 mb-1 border inline-flex rounded-full cursor-pointer bg-gray-200"
                  :class="[metricsSelected.includes(option.id) ? 'bg-green-300' : '']"
                  @click="onOptionClick(option.id)"
                >
                  <span :class="`text-xs text-gray-900`">{{ option.label }}</span>
                </div>
              </div>
            </sun-label-group>
            <div class="flex flex-row w-full mb-6">
              <sun-label-group text="Group by" class="w-full mr-3">
                <sun-select
                  v-model="groupBySelected"
                  :options="optionsSelect"
                  track-by="id"
                  label="label"
                  close-on-select
                  :allow-empty="false"
                />
              </sun-label-group>
            </div>
            <div class="flex flex-col w-full">
              <label class="font-bold text-left text-sm mr-1 sun-label text-gray-700 mb-1">Send to email</label>
              <div class="flex flex-1 flex-col items-start mb-4 sm:mb-0">
                <sun-toggle
                  id="toggle-report"
                  v-model="shouldSenToEmails"
                  class="mb-2"
                  name="toggle-report"
                  @change="selectSendToEmail"
                />
                <multi-select-emails
                  v-if="shouldSenToEmails"
                  :has-error="isEmptyEmails"
                  :error-message="errorMessageEmptyEmails"
                  @change="changeEmails"
                />
                <p-g-r-schedule-reports-items-list :id="dataReportModal.id" is-show />
              </div>
            </div>
          </div>
        </form-row>
      </div>
    </template>
    <template #footer>
      <div class="flex flex-wrap justify-between sm:px-3">
        <download-button
          v-if="!shouldSenToEmails"
          id="download-report-submit"
          :loading="isLoading"
          variant="pill"
          class="w-full text-sm custom-p-1 sm:w-auto"
        >
          <template #text>Download </template>
        </download-button>
        <send-email-button
          v-if="shouldSenToEmails"
          id="send-email-report-submit"
          :loading="isLoading"
          variant="pill"
          class="w-full text-sm custom-p-1 sm:w-auto"
        >
          <template #text>Send</template>
        </send-email-button>
        <sun-button variant="pill" class="w-full text-sm custom-p-1 sm:w-auto" color="white" @click="closeModal">
          Cancel
        </sun-button>
      </div>
    </template>
  </asterix-form-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AsterixFormModal from '@/components/organisms/shared/AsterixFormModal';
import FormRow from '@/components/atoms/FormRow/FormRow';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';
import DownloadButton from '@/components/atoms/DownloadButton.vue';
import { DATA_REPORT_MODAL, TYPE_REPORT_MODAL } from '@/store/modules/campaignManager/keys';
import { USER } from '@/store/modules/auth/keys';
import { ACTIVE_CONTEXT } from '@/store/modules/context/keys';
import CONFIG from '@/components/organisms/modules/dashboardSSP/campaignManager/config';
import MultiSelectEmails from '@/components/atoms/MultiSelectEmails/MultiSelectEmails.vue';
import PGRScheduleReportsItemsList from '@/components/organisms/modules/dashboardSSP/campaignManager/Mails/PGRScheduleReportsItemsList.vue';
import SendEmailButton from '@/components/atoms/SendEmailButton.vue';

export default {
  name: 'PGRCreateReportModal',
  components: {
    SendEmailButton,
    PGRScheduleReportsItemsList,
    MultiSelectEmails,
    DownloadButton,
    AsterixFormModal,
    FormRow,
  },
  mixins: [],
  props: {
    createReportFn: { type: Function, default: () => null },
    scheduleReportFn: { type: Function, default: () => null },
  },
  data: () => ({
    isLoading: false,
    fixedMetrics: CONFIG.fixedMetrics,
    optionalMetrics: CONFIG.optionalMetrics,
    metricsSelected: [],
    groupBySelected: CONFIG.groupBySelected,
    optionsAllowed: CONFIG.optionsAllowed,
    timezoneOptions: CONFIG.timezoneOptions,
    selectedAllValue: 'ALL',
    shouldSenToEmails: false,
    emails: [],
    isEmptyEmails: false,
    errorMessageEmptyEmails: CONFIG.errorMessageEmptyEmails,
  }),
  computed: {
    ...mapGetters({
      user: USER,
      contextActive: ACTIVE_CONTEXT,
      dataReportModal: DATA_REPORT_MODAL,
      typeReportModal: TYPE_REPORT_MODAL,
    }),
    userRole() {
      return this.user.contextRoles.find(role => role.context === this.contextActive.id).role;
    },
    optionsSelect() {
      return this.optionsAllowed[this.typeReportModal][this.dataReportModal.type.toLowerCase()].filter(option =>
        option.roles ? option.roles.includes(this.userRole) : true
      );
    },
  },
  methods: {
    ...mapActions({
      createToast: CREATE_TOAST,
    }),
    async submitForm({ valid }) {
      if (!valid) return;

      this.isEmptyEmails = false;
      this.isLoading = true;
      try {
        const params = {
          type: this.dataReportModal.type.toUpperCase(),
          id: this.dataReportModal.id,
          columns: this.metricsSelected.join(','),
          groupBy: this.groupBySelected.id,
          style: this.typeReportModal,
        };
        if (this.shouldSenToEmails) {
          if (!this.emails.length) {
            this.isEmptyEmails = true;
            this.isLoading = false;
            return;
          }
          params.addresses = this.emails;
          params.columns = this.metricsSelected;

          await this.scheduleReportFn({ config: params });
          this.createToast(Toast.success('Report scheduled', 'The report has been scheduled.'));
        } else {
          const blob = await this.createReportFn(params);
          this.downloadXlsx(blob, this.getFileName());
          this.createToast(Toast.success('Report Created', 'The report has been generated.'));
        }
      } catch (error) {
        if (error.status === 404) {
          this.createToast(Toast.warning('Report empty!', `Analytics not found for ${this.dataReportModal.type}`));
        } else {
          this.createToast(Toast.error('Oops! Something went wrong', error.message));
        }
      }

      this.isLoading = false;
      this.closeModal();
    },
    closeModal() {
      this.$emit('cancel');
    },
    onOptionClick(optionId) {
      const optionIndex = this.metricsSelected.indexOf(optionId);
      if (optionIndex > -1) {
        this.metricsSelected.splice(optionIndex, 1);
      } else {
        this.metricsSelected.push(optionId);
      }
    },
    getFileName() {
      const name = this.dataReportModal.name.replace(' ', '_');
      if (this.dataReportModal.type === 'Product') {
        return `${name}&${this.dataReportModal.oppName.replace(' ', '_')}`;
      }

      return name;
    },
    downloadXlsx(blob, nameFile) {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${nameFile}.xlsx`);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    },
    selectOptions(value) {
      if (value === 'ALL') {
        this.metricsSelected = this.optionalMetrics.map(option => option.id);
      } else {
        this.metricsSelected = [];
      }
    },
    selectSendToEmail(value) {
      if (!value) {
        this.emails = [];
      }
    },
    changeEmails(value) {
      this.isEmptyEmails = false;
      this.emails = value;
    },
  },
};
</script>

<style scoped>
::v-deep .sun-radio-checkbox {
  border-radius: 30px;
  border-width: 2px;
}
</style>
