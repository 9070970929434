var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("analytics-section", {
        attrs: {
          header: _vm.title,
          "table-key": _vm.tableKey,
          "loading-kpis": _vm.isLoadingKpis,
          "kpis-data": _vm.kpisData,
          "chart-loading": !_vm.isLoadingChart,
          "chart-options": _vm.chartOptions,
          "chart-series": _vm.chartSeries,
          "quick-filters-date": _vm.quickFiltersDate,
          "chart-yaxis": _vm.chartYaxis,
          "chart-type-selected": _vm.chartTypeSelected,
          "chart-revenue": _vm.chartRevenue,
          "has-filters": _vm.hasFilters,
          "report-options": _vm.reportOptions,
          "is-loading-table": _vm.isLoadingTable,
          "table-columns-definition": _vm.tableColumnsDefinition,
          "column-types": _vm.columnTypes,
          "row-model-type": _vm.rowModelType,
          "default-col-def": _vm.defaultColDef,
          "totals-column-names": _vm.totalsColumnNames,
          "totals-column": _vm.totalsColumn,
          totals: _vm.totals,
        },
        on: {
          chartTypeChanged: _vm.onChartTypeChange,
          resetFilters: _vm.resetFiltersBySection,
          handlerReport: _vm.handlerReportBySection,
          gridReady: _vm.onGridReady,
          changeFilters: _vm.updateActiveFiltersBySection,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }