<template>
  <asterix-section :status="sectionStatus" :is-loading="isLoadingSection">
    <template #header-right>
      <div>
        <sun-date-picker-v2
          id="economics-filter-date-range"
          ref="datePicker"
          :value="quickFiltersDate"
          :max-date="limitDateRange.max"
          no-header
          no-calendar-icon
          :range="true"
          show-date-list
          :date-list="dateList"
          @change="onQuickFiltersDate($event.value)"
          @select:date-list-option="selectDateOption"
        />
      </div>
    </template>
    <template #content>
      <ingestion-job-notifications
        v-if="activeClientId"
        :service="getIngestionJobNotifications"
        :client-id="activeClientId"
      />
      <branding-economics-section
        :key="`branding-${reloadSections}`"
        :quick-filters-date="quickFiltersDate"
        :query-params="setParamsToTable()"
        :platforms="allPlatforms"
        @finishedLoad="isLoadingSectionBranding = !$event"
      />
      <ott-economics-section
        :key="`ott-${reloadSections}`"
        :quick-filters-date="quickFiltersDate"
        :query-params="setParamsToTable()"
        :platforms="allPlatforms"
        @finishedLoad="isLoadingSectionOtt = !$event"
      />
      <youtube-economics-section
        :key="`youtube-${reloadSections}`"
        :quick-filters-date="quickFiltersDate"
        :query-params="setParamsToTable()"
        :platforms="allPlatforms"
        @finishedLoad="isLoadingSectionYoutube = !$event"
      />
      <performance-economics-section
        :key="`performance-${reloadSections}`"
        :quick-filters-date="quickFiltersDate"
        :query-params="setParamsToTable()"
        :platforms="allPlatforms"
        @finishedLoad="isLoadingSectionPerformance = !$event"
      />
      <skin-economics-section
        :key="`skin-${reloadSections}`"
        :quick-filters-date="quickFiltersDate"
        :query-params="setParamsToTable()"
        :platforms="allPlatforms"
        @finishedLoad="isLoadingSectionSkin = !$event"
      />
      <precio-economics-section
        :key="`precio-${reloadSections}`"
        :quick-filters-date="quickFiltersDate"
        :query-params="setParamsToTable()"
        :platforms="allPlatforms"
        @finishedLoad="isLoadingSectionPrecio = !$event"
      />
      <content-economics-section
        :key="`content-${reloadSections}`"
        :quick-filters-date="quickFiltersDate"
        :query-params="setParamsToTable()"
        :platforms="allPlatforms"
        @finishedLoad="isLoadingSectionContent = !$event"
      />
      <!--      <kleup-economics-section-->
      <!--        :key="`kleup-${reloadSections}`"-->
      <!--        :quick-filters-date="quickFiltersDate"-->
      <!--        :query-params="setParamsToTable()"-->
      <!--        :platforms="allPlatforms"-->
      <!--        @finishedLoad="isLoadingSectionKleup = !$event"-->
      <!--      />-->
      <others-economics-section
        :key="`others-${reloadSections}`"
        :quick-filters-date="quickFiltersDate"
        :query-params="setParamsToTable()"
        :platforms="allPlatforms"
        @finishedLoad="isLoadingSectionOthers = !$event"
      />
    </template>
  </asterix-section>
</template>

<script>
import { mapActions } from 'vuex';
import { addMonths } from 'date-fns';
import AsterixSection from '@/components/templates/AsterixSection';
import filtersMixin from '@/mixins/filters/filtersMixin';
import { queryParamsMixin } from '@/mixins/common/queryParamsMixin';
import IngestionJobNotifications from '@/components/molecules/shared/IngestionJobNotifications.vue';
import AnalyticsCellOrchestrator from '@/components/organisms/modules/thirdParty/AnalyticsCellOrchestrator.vue';
import BrandingEconomicsSection from '@/components/organisms/modules/thirdParty/analytics/BrandingEconomicsSection.vue';
import OthersEconomicsSection from '@/components/organisms/modules/thirdParty/analytics/OthersEconomicsSection.vue';
import OttEconomicsSection from '@/components/organisms/modules/thirdParty/analytics/OttEconomicsSection.vue';
import YoutubeEconomicsSection from '@/components/organisms/modules/thirdParty/analytics/YoutubeEconomicsSection.vue';
import PerformanceEconomicsSection from '@/components/organisms/modules/thirdParty/analytics/PerformanceEconomicsSection.vue';
import SkinEconomicsSection from '@/components/organisms/modules/thirdParty/analytics/SkinEconomicsSection.vue';
import ContentEconomicsSection from '@/components/organisms/modules/thirdParty/analytics/ContentEconomicsSection.vue';
// import KleupEconomicsSection from '@/components/organisms/modules/thirdParty/analytics/KleupEconomicsSection.vue';
import { Toast } from '@/model/shared/Toast';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { getIngestionJobNotifications } from '@/services/modules/ThirdParty/analytics';
import { getConnectedAndDemandPlatforms } from '@/services/modules/ThirdParty/platforms';
import { indexMixin } from '@/mixins/index/indexMixin';
import { stringToDate } from '@/filters/dateFilters';
import { SECTION_STATUS } from '@/model/shared/sectionStatus';
import { CONFIG } from './config';
import { HTTPStatusCode } from '@/model/shared/HTTPStatusCode';
import { CUSTOM_OPTION } from '@/views/private/modules/thirdParty/analytics/config';
import SunDatePickerV2 from '@/components/atoms/SunDatePickerV2/SunDatePickerV2.vue';
import PrecioEconomicsSection from '@/components/organisms/modules/thirdParty/analytics/PrecioEconomicsSection.vue';

export default {
  name: 'EconomicsDirect',
  components: {
    PrecioEconomicsSection,
    SunDatePickerV2,
    IngestionJobNotifications,
    BrandingEconomicsSection,
    OttEconomicsSection,
    YoutubeEconomicsSection,
    OthersEconomicsSection,
    PerformanceEconomicsSection,
    SkinEconomicsSection,
    ContentEconomicsSection,
    // KleupEconomicsSection,
    AsterixSection,
    // eslint-disable-next-line vue/no-unused-components
    AnalyticsCellOrchestrator,
  },
  mixins: [
    queryParamsMixin,
    filtersMixin({
      filters: [],
      filterQuick: {
        range_start: undefined,
        range_end: undefined,
      },
    }),
    indexMixin,
  ],
  data: () => ({
    isLoadingSectionBranding: true,
    isLoadingSectionPerformance: true,
    isLoadingSectionYoutube: true,
    isLoadingSectionOtt: true,
    isLoadingSectionOthers: true,
    isLoadingSectionSkin: true,
    isLoadingSectionPrecio: true,
    isLoadingSectionContent: true,
    isLoadingSectionKleup: false,
    // Config DatePicker
    datepickerKey: 0,
    dateList: CONFIG.dateList,
    sectionStatus: SECTION_STATUS.OK,
    defaultDate: CONFIG.defaultDate,
    // Platforms
    allPlatforms: [],
    reloadSections: 0,
  }),
  computed: {
    isLoadingSection() {
      return (
        this.isLoadingSectionBranding ||
        this.isLoadingSectionPerformance ||
        this.isLoadingSectionYoutube ||
        this.isLoadingSectionOtt ||
        this.isLoadingSectionOthers ||
        this.isLoadingSectionSkin ||
        this.isLoadingSectionPrecio ||
        this.isLoadingSectionContent ||
        this.isLoadingSectionKleup
      );
    },
    // Computed for DatePicker -----------------------------------
    quickFiltersDate() {
      const rangeStart = this.filterQuick.range_start;
      const rangeEnd = this.filterQuick.range_end;

      const startDate = rangeStart ? stringToDate(rangeStart) : this.defaultDate.startDate;
      const endDate = rangeEnd ? stringToDate(rangeEnd) : this.defaultDate.endDate;
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 0);

      return { startDate, endDate };
    },
    limitDateRange() {
      const { startDate } = this.quickFiltersDate;
      const max = addMonths(startDate, 6);
      return {
        max: max < CONFIG.limitMaxDate ? max : CONFIG.limitMaxDate,
      };
    },
  },
  async mounted() {
    // esto no lo entiendo aun
    await this.filtersLoadAllfiltersOnMounted(CONFIG.COMMON_ID_VIEW);
    this.setDefaultDateFilter();
    await this.getAllPlatforms();
    await this.filtersUpdateFiltersOnStoreAndURL(CONFIG.COMMON_ID_VIEW);
  },
  methods: {
    ...mapActions({
      createToast: CREATE_TOAST,
    }),
    getIngestionJobNotifications,
    // Methods for DatePicker -----------------------------------
    async onQuickFiltersDate(value) {
      this.setFilterQuick(value || this.defaultDate);
      this.resetSections();
      await this.filtersUpdateFiltersOnStoreAndURL(CONFIG.COMMON_ID_VIEW);
    },
    resetSections() {
      this.isLoadingSectionBranding = true;
      this.isLoadingSectionPerformance = true;
      this.isLoadingSectionYoutube = true;
      this.isLoadingSectionOtt = true;
      this.isLoadingSectionOthers = true;
      this.isLoadingSectionSkin = true;
      this.isLoadingSectionPrecio = true;
      this.isLoadingSectionContent = true;
      this.isLoadingSectionKleup = true;
      this.reloadSections++;
    },
    selectDateOption(option) {
      if (option === CUSTOM_OPTION) {
        setTimeout(() => (this.$refs.datePicker.$refs.menu.isMenuActive = true), 0);
      }
    },
    // Methods for filters -----------------------------------
    setFilterQuick({ startDate, endDate }) {
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 0);
      this.filterQuick.range_start = startDate.toISOString();
      this.filterQuick.range_end = endDate.toISOString();

      // TODO Is this necessary?
      this.datepickerKey++;
    },
    setDefaultDateFilter() {
      this.setFilterQuick(this.quickFiltersDate);
    },
    // Methods for requests -----------------------------------
    async getAllPlatforms() {
      try {
        const { data } = await getConnectedAndDemandPlatforms();
        this.allPlatforms = data;
      } catch (error) {
        if (error.code !== HTTPStatusCode.Cancel) {
          this.sectionStatus = SECTION_STATUS.ERROR;
          this.createToast(Toast.error('Platforms', 'We can not load Platforms'));
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~ag-grid-community/dist/styles/ag-grid.css';
@import '~ag-grid-community/dist/styles/ag-theme-alpine.css';

.breakdown {
  min-width: 280px;
}

:deep(.main-headers) {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

:deep(.ag-sort-none-icon) {
  margin-left: 0px !important;
}

:deep(.border-r-transparent) {
  border-right-color: transparent;
}

:deep(.date.z-10) {
  @apply z-0;
}

:deep(.ag-theme-alpine .ag-root-wrapper) {
  border: 0;
}

:deep(.ag-theme-alpine .ag-header) {
  @apply bg-gray-300 border-0;
}

:deep(.ag-theme-alpine .ag-header-cell) {
  @apply px-1;
}

:deep(.ag-theme-alpine .ag-header-cell-label),
:deep(.ag-theme-alpine .ag-cell-value) {
  @apply text-xs px-1;
}

:deep(.ag-theme-alpine .ag-floating-filter-button) {
  display: none;
}

:deep(.ag-theme-alpine .ag-watermark) {
  opacity: 0 !important;
  display: none !important;
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
  right: auto !important;
  bottom: auto !important;
  visibility: hidden !important;
  transition: none !important;
}

:deep(.ag-theme-alpine .ag-row.deal-starting) {
  @apply text-green-600 bg-green-100;
}

:deep(.ag-theme-alpine .ag-row.deal-ending) {
  @apply text-red-500 bg-red-100;
}

:deep(.ag-theme-alpine .ag-row.deal-starting [aria-colindex='1']),
:deep(.ag-theme-alpine .ag-row.deal-starting [col-id='ag-Grid-AutoColumn']),
:deep(.ag-theme-alpine .ag-row.deal-ending [aria-colindex='1']),
:deep(.ag-theme-alpine .ag-row.deal-ending [col-id='ag-Grid-AutoColumn']) {
  @apply pl-5;
}

:deep(.ag-theme-alpine .ag-row.deal-starting [aria-colindex='1']:before),
:deep(.ag-theme-alpine .ag-row.deal-starting [col-id='ag-Grid-AutoColumn']:before),
:deep(.ag-theme-alpine .ag-row.deal-ending [aria-colindex='1']:before),
:deep(.ag-theme-alpine .ag-row.deal-ending [col-id='ag-Grid-AutoColumn']:before) {
  content: '';
  position: absolute;
  top: 14px;
  left: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  @apply bg-green-600;
}

:deep(.ag-theme-alpine .ag-row.deal-starting.ag-row-level-1 [aria-colindex='1']),
:deep(.ag-theme-alpine .ag-row.deal-starting.ag-row-level-1 [col-id='ag-Grid-AutoColumn']),
:deep(.ag-theme-alpine .ag-row.deal-ending.ag-row-level-1 [aria-colindex='1']),
:deep(.ag-theme-alpine .ag-row.deal-ending.ag-row-level-1 [col-id='ag-Grid-AutoColumn']) {
  @apply pl-0;
}

:deep(.ag-theme-alpine .ag-row.deal-starting.ag-row-level-1 [aria-colindex='1']:before),
:deep(.ag-theme-alpine .ag-row.deal-starting.ag-row-level-1 [col-id='ag-Grid-AutoColumn']:before),
:deep(.ag-theme-alpine .ag-row.deal-ending.ag-row-level-1 [aria-colindex='1']:before),
:deep(.ag-theme-alpine .ag-row.deal-ending.ag-row-level-1 [col-id='ag-Grid-AutoColumn']:before) {
  left: 38px;
}

:deep(.ag-theme-alpine .ag-row.deal-ending [aria-colindex='1']:before),
:deep(.ag-theme-alpine .ag-row.deal-ending [col-id='ag-Grid-AutoColumn']:before) {
  @apply bg-red-600;
}

:deep(.ag-theme-alpine .ag-ltr .ag-row-group-indent-2:not(.ag-cell-expandable)) {
  padding-left: 50px;
}
:deep(.ag-theme-alpine .ag-ltr .ag-row-group-indent-1:not(.ag-cell-expandable)) {
  padding-left: 30px;
}
:deep(.ag-theme-alpine .ag-ltr .ag-row-group-leaf-indent:not(.ag-cell-expandable)) {
  margin-left: 0;
}
:deep(.platform-status-modal) {
  top: 1%;
}
</style>
