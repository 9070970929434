import { thirdParty } from '..';
import { getPlatforms } from './getPlatforms';
import getPlatformsById from './getPlatformsById';
import { getNotConnectedPlatforms } from './getNotConnectedPlatforms';
import { getConnectedPlatforms } from './getConnectedPlatforms';
import { reloadPlatformData } from './reloadPlatformData';
import { getConnectedAndDemandPlatforms } from './getConnectedAndDemandPlatforms';

export const api = thirdParty;

export {
  getPlatforms,
  getPlatformsById,
  getNotConnectedPlatforms,
  getConnectedPlatforms,
  reloadPlatformData,
  getConnectedAndDemandPlatforms,
};
